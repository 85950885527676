import { navigate } from "gatsby"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { RouteComponentProps } from "@reach/router"

import { useNotify } from "../notifications"
import { RootState } from "../state/rootReducer"

interface Props extends RouteComponentProps {
  component?: any // This should be `React.ComponentType<{ user?: any }>` but this doesn't work
  unauthorized?: any
  admin?: boolean
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  unauthorized: Unauthorized,
  admin = false,
  ...props
}: Props) => {
  const notify = useNotify()
  const currentUser = useSelector((state: RootState) => state.auth.currentUser)

  useEffect(() => {
    if (!currentUser) {
      notify.warning("You need to sign in before continuing!")
      navigate("/sign-in", { replace: true })
    }
  }, [currentUser, notify])

  if (currentUser) {
    if (admin && !currentUser.admin) {
      return <Unauthorized user={currentUser} {...props} />
    }
    return <Component user={currentUser} {...props} />
  }

  return null
}

export default PrivateRoute
