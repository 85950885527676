export const formatAmount = (amount: number) => {
  let result = "0"
  if (amount) {
    result = amount.toLocaleString("en-ZA", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  return result
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber.startsWith("0")) {
    phoneNumber = phoneNumber.replace("0", "+27")
  }
  return phoneNumber
}
