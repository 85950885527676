/* eslint-disable @typescript-eslint/camelcase */
import { Field, Formik, FormikHelpers, FormikProps, ErrorMessage } from "formik"
import {
  Form,
  PasswordField,
  SubmitButton,
  TextField,
} from "formik-material-ui-elements"
import React, { ReactNode } from "react"
import * as yup from "yup"

import Grid from "@material-ui/core/Grid"

import { validatePhoneNumber } from "../../utils/phonenumber"
import styled from "styled-components"

const validationSchema = yup.object().shape({
  given_name: yup.string().required("Required"),
  family_name: yup.string().required("Required"),
  id_number: yup.string().required("Required"),
  phone_number: yup
    .string()
    .required("Required")
    .test("phone-number", "Invalid phone number", (value) =>
      validatePhoneNumber(value),
    ),
  password: yup
    .string()
    .required("Required")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character",
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
})

const LoginButton = styled(SubmitButton)`
  margin: auto;
  border-radius: 8px;
`

export interface FormValues {
  given_name: string
  family_name: string
  id_number: string
  phone_number: string
  password: string
  confirmPassword:string
}

interface Props {
  onSubmit: (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => void | Promise<any>
  initialValues?: FormValues
}

const defaultValues = {
  given_name: "",
  family_name: "",
  id_number: "",
  phone_number: "",
  password: "",
  confirmPassword: "",
}

const SignUpForm: React.FC<Props> = ({
  onSubmit,
  initialValues = defaultValues,
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        errors,
        touched,
      }: FormikProps<FormValues>): ReactNode => (
        <Form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                autoComplete="given-name"
                autoFocus
                component={TextField}
                id="given_name"
                label="First Name"
                name="given_name"
                color="secondary"
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                autoComplete="family-name"
                component={TextField}
                id="family_name"
                label="Last Name"
                name="family_name"
                color="secondary"
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                // validate={validateIDNumber}
                id="id_number"
                label="ID Number/Passport Number"
                name="id_number"
                color="secondary"
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                autoComplete="tel"
                component={TextField}
                id="phone_number"
                placeholder="E.g. 0812345678"
                label="Mobile Number"
                name="phone_number"
                color="secondary"
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                autoComplete="current-password"
                component={PasswordField}
                id="password"
                label="Password"
                name="password"
                color="secondary"
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="confirmPassword"
                type="password"
                size="small"
                label="Confirm Password"
                color="secondary"
                component={PasswordField}
                className={
                  errors.confirmPassword && touched.confirmPassword
                    ? " is-invalid"
                    : ""
                }
              />
            </Grid>
          </Grid>
          <LoginButton
            color="primary"
            variant="contained"
            fullWidth
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create Account
          </LoginButton>
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
