import { useEffect } from 'react';

export const usePointerGlow = (sidebarRef: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const handlePointerMove = (event: PointerEvent) => {
      if (!sidebarRef.current) return;

      // Get the sidebar's bounding box
      const rect = sidebarRef.current.getBoundingClientRect();
      
      // Calculate the mouse position relative to the sidebar
      const x = (event.clientX - rect.left).toFixed(2);
      const y = (event.clientY - rect.top).toFixed(2);
      
      // Calculate percentage positions relative to the sidebar
      const xp = ((event.clientX - rect.left) / rect.width).toFixed(2);
      const yp = ((event.clientY - rect.top) / rect.height).toFixed(2);

    //   console.log({ x, y, xp, yp });

      // Update CSS variables in the root of the document
      sidebarRef.current.style.setProperty('--x', `${x}px`);
      sidebarRef.current.style.setProperty('--y', `${y}px`);
      sidebarRef.current.style.setProperty('--xp', xp);
      sidebarRef.current.style.setProperty('--yp', yp);
    };

    // Attach event listener for mouse/pointer movements
    window.addEventListener('pointermove', handlePointerMove);

    return () => {
      // Cleanup event listener on unmount
      window.removeEventListener('pointermove', handlePointerMove);
    };
  }, [sidebarRef]);
};
