import * as React from "react"
import styled from "styled-components"

import { SEO } from "../components"

const Container = styled.div`
  height: 100vh;
`

interface Props {
  [name: string]: any
}

const NotFoundPage: React.FC<Props> = () => (
  <React.Fragment>
    <SEO title="Not Found" />
    <Container>
      <p>We can&#39;t seem to find the page you are looking for.</p>
    </Container>
  </React.Fragment>
)

export default NotFoundPage
