import { useMutation, useQuery } from "@apollo/react-hooks"
import { CircularProgress, Grid } from "@material-ui/core"
import { Logger } from "aws-amplify"
import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNotify } from "../../notifications"
import { RootState } from "../../state/rootReducer"
import {
  ASSIGN_COGNITO_USERNAME,
  MERGE_USER_MOBILE,
  GET_EMPLOYEE_BY_ID_NUMBER,
} from "./graphql"
import MainContainer from "./layout/MainContainer"
import UserNotFoundView from "./User404"

const Dashboard: React.FC<{}> = () => {
  const logger = useMemo(() => new Logger(Dashboard.name), [])
  const notify = useNotify()
  const [AssignCognitoUserName] = useMutation(ASSIGN_COGNITO_USERNAME)
  const [UpdateEmployeeMobile] = useMutation(MERGE_USER_MOBILE)

  const currentUser = useSelector<RootState>(
    (state) => state.auth.currentUser,
  ) as any
  const { data, loading, refetch, error } = useQuery(
    GET_EMPLOYEE_BY_ID_NUMBER,
    {
      variables: {
        employeesID: currentUser["custom:id_number"].trim(),
      },
    },
  )

  const [me, setMe] = useState({
    employee: {
      salaryAdvances: [],
    },
  })

  const assignCognitoUsername = async () => {
    try {
      await AssignCognitoUserName({
        variables: { employee: data.employees[0].id },
      })
      notify.success("Your congito username has been set successfully.")
    } catch (err) {
      logger.error(err)
      notify.error(err.message)
    }
  }

  const updateMobileNumber = async () => {
    try {
      await UpdateEmployeeMobile({
        variables: { employee: data.employees[0].id },
      })
      notify.success("Your mobile number has been update successfully.")
    } catch (err) {
      logger.error(err)
      notify.error(err.message)
    }
  }

  useEffect(() => {
    if (!loading) {
      if (data?.employees.length) {
        setMe({ employee: data.employees[0] })
        if (data?.employees[0]?.cognito_username == null || undefined) {
          assignCognitoUsername()
        }
        if (
          data?.employees[0]?.mobile !=
          currentUser.phone_number.replace(/\+27/, "0")
        ) {
          updateMobileNumber()
          refetch()
        }
      }
    }
  }, [data, loading])

  if (loading)
    return (
      <Grid
        container
        style={{
          position: "fixed",
          alignItems: "cennter",
          bottom: "45%",
          left: "45%",
        }}
      >
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )

  const employeeObj = me.employee
  if (!me.employee) {
    return (
      <Grid container>
        <Grid container>
          <UserNotFoundView />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid container>
        <MainContainer employeeObj={employeeObj} refetch={refetch} />
      </Grid>
    </Grid>
  )
}

export default Dashboard
