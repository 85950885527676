import gql from "graphql-tag"

export const USER_QUERY = gql`
  query getUser {
    me {
      employee {
        id
        salaryAdvances {
          id
          refNumber
          advanceDate
          earnedAmount
          accessibleAmount
          advanceAmount
          status
        }
      }
    }
  }
`

export const LINK_EMPLOYEE_MUTATION = gql`
  mutation LinkEmployee($employer: ID!) {
    linkEmployee(input: { employerId: $employer }) {
      ... on LinkEmployeeSuccess {
        employee {
          id
        }
      }
      ... on NotFoundError {
        message
      }
      ... on ConflictError {
        message
      }
    }
  }
`

export const ASSIGN_COGNITO_USERNAME = gql`
  mutation AssignCognitoUsername($employee: ID!) {
    assignCognitoUsername(input: { employeeId: $employee }) {
      ... on UpdateCurrentUserSuccess {
        employee {
          id
        }
      }
      ... on NotFoundError {
        message
      }
    }
  }
`

export const MERGE_USER_MOBILE = gql`
  mutation MergeUserMobileNumber($employee: ID!) {
    mergeUserMobileNumber(input: { employeeId: $employee }) {
      ... on UpdateCurrentUserSuccess {
        employee {
          id
        }
      }
      ... on NotFoundError {
        message
      }
    }
  }
`

export const UPDATE_DETAIL_CONFIRM_TIMESTAMP = gql`
  mutation UpdateDetailConfirmTimestamp($timestamp: String!) {
    udpateDetailConfirmTimestamp(
      input: { detailConfirmTimestamp: $timestamp }
    ) {
      ... on UpdateEmployeeSuccess {
        employee {
          id
        }
      }
      ... on NotFoundError {
        message
      }
    }
  }
`

export const CREATE_DETAIL_MUTATION = gql`
  mutation CreateDetailCorrectionSuccess(
    $nameAndSurname: Boolean
    $idNumber: Boolean
    $mobileNumber: Boolean
    $email: Boolean
    $employeeName: Boolean
    $employeeNumber: Boolean
    $paydayCycle: Boolean
    $nettSalary: Boolean
    $bank: Boolean
    $accountNumber: Boolean
    $accountBranchCode: Boolean
    $accountType: Boolean
  ) {
    createDetailCorrection(
      input: {
        nameAndSurname: $nameAndSurname
        idNumber: $idNumber
        mobileNumber: $mobileNumber
        email: $email
        employeeName: $employeeName
        employeeNumber: $employeeNumber
        paydayCycle: $paydayCycle
        nettSalary: $nettSalary
        bank: $bank
        accountNumber: $accountNumber
        accountBranchCode: $accountBranchCode
        accountType: $accountType
      }
    ) {
      ... on CreateDetailCorrectionSuccess {
        correctionDetail {
          id
        }
      }
    }
  }
`

export const CREATE_ADVANCE_MUTATION = gql`
  mutation CreateAdvance(
    $advanceAmount: Float!
    $termAndConditionAcceptTimestamp: String!
    $paymentOption: String!
  ) {
    createAdvance(
      input: {
        advanceAmount: $advanceAmount
        termAndConditionAcceptTimestamp: $termAndConditionAcceptTimestamp
        paymentOption: $paymentOption
      }
    ) {
      ... on CreateAdvanceSuccess {
        advance {
          id
        }
      }
    }
  }
`

export const GENERATE_ADVANCE_QUOTE_MUTATION = gql`
  mutation GenerateAdvanceQuote(
    $advanceAmount: Float!
    $paymentOption: String!
  ) {
    generateAdvanceQuote(
      input: { advanceAmount: $advanceAmount, paymentOption: $paymentOption }
    ) {
      ... on CreateQuoteSuccess {
        quote {
          advanceAmount
          transactionFee
          bankTransfer
          requestDate
          actionDate
          salaryDeductionDate
        }
      }
    }
  }
`

export const CREATE_ADVANCE_MUTATION_2 = gql`
  mutation CreateAdvance_($advanceAmount: Float!, $paymentTime: Int!) {
    createAdvance_(
      input: { advanceAmount: $advanceAmount, paymentTime: $aymentTime }
    ) {
      ... on CreateAdvanceSuccess {
        advance {
          id
        }
      }
    }
  }
`

export const REQUEST_ADVANCE_PAYMENT_OPTIONS = gql`
  mutation RequestPaymentOption(
    $employee: ID!
    $platform: String!
    $requestAmount: Float!
  ) {
    requestPaymentOption(
      input: {
        employeeId: $employee
        platform: $platform
        requestAmount: $requestAmount
      }
    ) {
      ... on RequestPaymentOptionsSuccess {
        paymentOptions
      }
    }
  }
`

export const REQUEST_EMPLOYEE_INDEX_STATUS = gql`
  mutation GetEmployeeIndexAlertStatus($employee: ID!) {
    getEmployeeIndexAlertStatus(input: { employeeId: $employee }) {
      ... on GetEmployeeIndexAlertStatusSuccess {
        hasFinancialDistressAlert
      }
    }
  }
`

export const CREATE_PAYCHECK_INDEX_REQUEST = gql`
  mutation CreateIndexRequest($employee: ID!) {
    createIndexRequest(input: { employeeId: $employee }) {
      ... on CreateIndexRequestSuccess {
        success
      }
    }
  }
`

export const GET_EMPLOYERS = gql`
  query getEmployers($nodeId: ID = 1) {
    employers {
      id
      registrationNumber
      dateOfIncoporation
      salaryPayDate
      salaryCutOffDay
      hrContactName
      name
    }
    ping
    node(id: $nodeId) {
      id
    }
  }
`

export const GET_EMPLOYEES = gql`
  query getAllEmployees {
    employees {
      id
      name
      surname
      employeeNumber
      mobile
      dateOfBirth
      gender
      dateOfEmployment
      terminationDate
      grossSalary
      nettSalary
      advanceDate
      earnedAmount
      bankName
      branchCode
      accountNumber
      accountType
      email
      occupation
      paymentCode
      salaryAdvances {
        id
        refNumber
        advanceDate
        earnedAmount
        accessibleAmount
        advanceAmount
        bankCharge
        repaymentAmount
        repaymentDate
        transactionFee
        status
      }
    }
  }
`
export const GET_EMPLOYEE_BY_MOBILE = gql`
  query getEmployeeByMobile($employeesMobile: String) {
    employees(mobile: $employeesMobile) {
      id
      name
      surname
      accessibleAmount
      employeeNumber
      idNumber
      mobile
      dateOfBirth
      gender
      grossSalary
      nettSalary
      earnedAmount
      bankName
      branchCode
      accountNumber
      accountType
      email
      cognitoUsername
      payCycle
      employer {
        name
      }
      salaryAdvances {
        id
        refNumber
        advanceDate
        earnedAmount
        accessibleAmount
        advanceAmount
        bankTransferFee
        repaymentDate
        transactionFee
        status
      }
    }
  }
`

export const GET_EMPLOYEE_BY_ID_NUMBER = gql`
  query getEmployeeByIdNumber($employeesID: String) {
    employees(idNumber: $employeesID) {
      id
      name
      surname
      accessibleAmount
      employeeNumber
      idNumber
      mobile
      dateOfBirth
      gender
      grossSalary
      nettSalary
      earnedAmount
      bankName
      branchCode
      accountNumber
      accountType
      email
      cognitoUsername
      payCycle
      celbuxMobileNumber
      employer {
        name
      }
      salaryAdvances {
        id
        refNumber
        advanceDate
        earnedAmount
        accessibleAmount
        advanceAmount
        bankTransferFee
        repaymentDate
        transactionFee
        status
      }
    }
  }
`
