import * as React from "react"

import { SEO } from "../../components"
import BrochureContainer from "../BrochureContainer"

const ContactPage: React.FC<{}> = () => (
  <BrochureContainer>
    <SEO title="Contact us" description="" />
    <h1>Contact us</h1>
    <p>...</p>
  </BrochureContainer>
)

export default ContactPage
