import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

declare module "@material-ui/core/styles" {
  interface Palette {
    appContainerBackground: string
  }

  interface PaletteOptions {
    appContainerBackground: string
  }
}

const paywellTheme = createMuiTheme({
  typography: {
    fontFamily: [
      "Gilroy",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#1e4489",
      dark: "#62c5c4",
    },
    secondary: {
      main: "#673bb6",
      dark: "#a2dbd9",
      light: "#f7f9fd",
    },
    error: {
      main: "#b11f24",
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#414042",
      secondary: "#000",
    },
    info: {
      main: "#a2dbd9",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: "1px",
        padding: "15px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "12pt",
        width: "200px;",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      text: {
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "12pt",
      },
    },
  },
})

export default paywellTheme
