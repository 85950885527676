import { Grid, makeStyles, Typography, Box } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"
import { isMobile } from "react-device-detect"
const useStyles = makeStyles({
  root: {
    height: "70vh",
    display: "flex",
    justifyContent: "center",
    alignContent: "center"
  },
})

const browserOrders = [1, 2, 3, 4]
const mobileOrders = [2, 3, 1, 4]

let orders = isMobile ? mobileOrders : browserOrders

const UserNotFoundView: React.FC<any> = ({}: any) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} spacing={2} justify="center" >
      <Grid item xs={12}>
        <Box component="div">
          <Typography
            component="h1"
            variant="h2"
            color="inherit"
            align="center"
          >
            OOPS!
          </Typography>
        </Box>

        <Box>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            align="center"
          >
            Failed to retrieve user details, please contact administrator to
            address your issue.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

UserNotFoundView.propTypes = {
  className: PropTypes.string,
}

export default UserNotFoundView
