import { EnhancedStore, configureStore } from "@reduxjs/toolkit"

import rootReducer from "./rootReducer"

const createStore = (preloadedState: {} = {}): EnhancedStore => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
  })

  // @ts-ignore
  if (process.env.NODE_ENV !== "production" && module.hot) {
    // @ts-ignore
    module.hot.accept("./rootReducer", () => store.replaceReducer(rootReducer))
  }

  return store
}

export default createStore
