import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

interface Props {
  lang?: string
  title?: string
  description?: string
  image?: string
  pathname?: string
  article?: boolean
}

const SEO: React.FC<Props> = (props: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            lang
            title
            titleTemplate
            description
            siteUrl
            image
            twitterUsername
          }
        }
      }
    `
  )

  const lang = props.lang || site.siteMetadata.lang
  const title = props.title || site.siteMetadata.title
  const titleTemplate = site.siteMetadata.titleTemplate || `${title} | %s`
  const description = props.description || site.siteMetadata.description
  const url = `${site.siteMetadata.siteUrl}${props.pathname || "/"}`
  const image = `${site.siteMetadata.siteUrl}${
    props.image || site.siteMetadata.image
  }`
  const twitterUsername = site.siteMetadata.twitterUsername

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
    >
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      {url && <meta property="og:url" content={url} />}
      {props.article && <meta property="og:type" content="article" />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  )
}

export default SEO
