/* eslint-disable @typescript-eslint/camelcase */
import { Field, Formik, FormikHelpers, FormikProps } from "formik"
import { Form, SubmitButton, TextField } from "formik-material-ui-elements"
import React, { ReactNode } from "react"
import styled from "styled-components"
import * as yup from "yup"

const validationSchema = yup.object().shape({
  phone_number: yup.string().required("Required"),
})

export interface FormValues {
  phone_number: string
}

interface Props {
  onSubmit: (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => void | Promise<any>
  initialValues?: FormValues
}

const defaultValues = {
  phone_number: "",
}

const ForgotPasswordButton = styled(SubmitButton)`
  margin: auto;
  border-radius: 8px;
`

const ForgotPasswordForm: React.FC<Props> = ({
  onSubmit,
  initialValues = defaultValues,
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }: FormikProps<FormValues>): ReactNode => (
      <Form noValidate>
        <Field
          autoComplete="tel"
          component={TextField}
          id="phone_number"
          label="Mobile Number"
          name="phone_number"
          color="secondary"
          size="small"
          required
        />
        <ForgotPasswordButton
          color="primary"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Send
        </ForgotPasswordButton>
      </Form>
    )}
  </Formik>
)

export default ForgotPasswordForm
