import { PayloadAction, createReducer } from "@reduxjs/toolkit"

import { notify } from "./actions"
import type { NotifyPayload } from "./actions"

interface NotificationState {
  notification: NotifyPayload | null
}

const reducer = createReducer(
  {
    notification: null,
  } as NotificationState,
  {
    [notify.type]: (state, action: PayloadAction<NotifyPayload>) => {
      state.notification = action.payload
    },
  },
)

export default reducer
