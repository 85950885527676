import { Field, Formik, FormikHelpers, FormikProps } from "formik"
import {
  Form,
  PasswordField,
  SubmitButton,
  TextField,
} from "formik-material-ui-elements"
import styled from "styled-components"
import React, { ReactNode } from "react"
import * as yup from "yup"

const validationSchema = yup.object().shape({
  code: yup.string().required("Required"),
  password: yup.string().required("Required"),
  passwordConfirmation: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), ""], "Passwords don't match"),
})

export interface FormValues {
  code: string
  password: string
  passwordConfirmation: string
}

interface Props {
  onSubmit: (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => void | Promise<any>
  initialValues?: FormValues
}

const defaultValues = {
  code: "",
  password: "",
  passwordConfirmation: "",
}

const ResetPasswordButton = styled(SubmitButton)`
  margin: auto;
  border-radius: 8px;
`

const ResetPasswordForm: React.FC<Props> = ({
  onSubmit,
  initialValues = defaultValues,
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }: FormikProps<FormValues>): ReactNode => (
      <Form noValidate>
        <Field
          autoFocus
          component={TextField}
          id="code"
          label="Confirmation Code"
          name="code"
          color="secondary"
          size="small"
          required
        />
        <Field
          component={PasswordField}
          id="password"
          label="Password"
          name="password"
          type="password"
          color="secondary"
          size="small"
          required
        />
        <Field
          component={PasswordField}
          id="passwordConfirmation"
          label="Confirm Password"
          name="passwordConfirmation"
          type="password"
          color="secondary"
          size="small"
          required
        />
        <ResetPasswordButton
          color="secondary"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Reset
        </ResetPasswordButton>
      </Form>
    )}
  </Formik>
)

export default ResetPasswordForm
