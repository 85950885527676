export const payCycles: { [id: string] : string; } = {
  "m5" : "Monthly 5 workdays",
  "m6" : "Monthly 6 workdays",
  "m7" : "Monthly 7 workdays",
  "w5" : "Weekly 5 workdays",
  "w6" : "Weekly 6 workdays",
  "w7" : "Weekly 7 workdays",
  "bi5" : "Bi-Monthly 5 workdays",
  "bi6" : "Bi-Monthly 6 workdays",
  "bi7" : "Bi-Monthly 7 workdays",
}