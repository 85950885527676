import React from "react"
import gql from "graphql-tag"

import { Field } from "formik"
import { AutocompleteField } from "formik-material-ui-elements"

const EMPLOYERS_QUERY = gql`
  query findEmployerByName($filter: String) {
    employers(name: $filter) {
      id
      name
    }
  }
`

interface Props {
  required: boolean
  label: string
}

const EmployerSearch: React.FC<Props> = ({
  required = false,
  label = "",
}: Props) => {
  return (
    <Field
      component={AutocompleteField}
      resultPath="employers"
      id="employer"
      name="employer"
      label={label}
      query={EMPLOYERS_QUERY}
      labelPath="name"
      valuePath="id"
      required={required}
    />
  )
}

export default EmployerSearch
