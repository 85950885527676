import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

export interface Props {
  img: any
}

const Container = styled(Img)<any>`
  width: 100%;
  height: 100%;
`

const Image: React.FC<Props> = ({ img }: Props) => {
  return (
    <Container fluid={img.childImageSharp.fluid} backgroundColor={"#000000"} />
  )
}

export default Image
