import React from "react"

import { Layout } from "./components"

interface Props {
  element?: any
  props?: {}
}

const wrapPageElement: React.FC<Props> = ({ element, props }: Props) => {
  return (
    <Layout {...props}>{element}</Layout>
  )
}

export default wrapPageElement
