import lottie, { AnimationItem } from "lottie-web"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"

export interface Props {
  data: any
  active: boolean
  paused: boolean
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const LottiePlayer: React.FC<Props> = ({ data, active, paused }: Props) => {
  const anim = useRef<AnimationItem>()
  const containerEl = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (active && !paused) {
      anim?.current?.play()
    } else if (active && paused) {
      anim?.current?.pause()
    } else {
      anim?.current?.stop()
    }
  }, [active, paused])

  useEffect(() => {
    const init = (): void => {
      if (containerEl.current) {
        anim.current = lottie.loadAnimation({
          container: containerEl.current,
          renderer: "svg",
          loop: true,
          autoplay: false,
          animationData: data,
        })
      }
    }

    init()
  }, [data])

  return <Container ref={containerEl} />
}

export default LottiePlayer
