import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { SEO } from "../../components"
import BrochureContainer from "../BrochureContainer"
import Difference from "./Difference"
import Landing from "./Landing"
import Refer from "./Refer"
import WhyPaycheck from "./WhyPaycheck"
import WithPaycheck from "./WithPaycheck"

const HomePage: React.FC<{}> = () => {

  useEffect(() => {
    navigate("/sign-in", { replace: true })
  }, [])
  return (null)
  // return (
  //   <BrochureContainer>
  //     <SEO title="Home" description="" />
  //     <Landing />
  //     <WithPaycheck />
  //     <WhyPaycheck />
  //     <Difference />
  //     <Refer />
  //   </BrochureContainer>
  // )
}

export default HomePage
