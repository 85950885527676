import React from "react"

import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

interface Props {
  children?: any
}

const FormContainer: React.FC<Props> = ({ children, ...props }: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} {...props}>
      {children}
    </Box>
  )
}

export default FormContainer
