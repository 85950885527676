import React from "react"
import { Button as ReactButton, withStyles } from "@material-ui/core"

const TextButton: React.FC<any> = (props: any) => {
  const { text, className } = props

  const Button = withStyles((theme) => ({
    root: {
      width: "initial",
      margin: "4px",
      padding: "6px 20px",
      borderRadius: "5px",
      // backgroundColor: theme.palette.primary.main,
      fontWeight: "normal",
      fontSize: "13px",
      "&.Mui-disabled": {
        background: "#C5CBD8",
      },
    },
  }))(ReactButton)

  return (
    <Button {...props} className={className}>
      {text}
    </Button>
  )
}

export default TextButton
