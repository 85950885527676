import { Auth, Logger } from "aws-amplify"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import { Router } from "@reach/router"

import { setCurrentUser } from "./auth/actions"
import DashboardPage from "./DashboardPage"
import NotFoundPage from "./NotFoundPage"
import PrivateRoute from "./PrivateRoute"

const App: React.FC<{}> = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const logger = useMemo(() => new Logger(App.name), [])

  const fetchAuthenticatedUser = useCallback(async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      logger.debug("Auth.currentAuthenticatedUser() => ", currentUser)
      dispatch(setCurrentUser(currentUser.attributes))
    } catch (err) {
      logger.error(err)
      dispatch(setCurrentUser(null))
    } finally {
      setLoading(false)
    }
  }, [dispatch, logger])

  useEffect(() => {
    fetchAuthenticatedUser()
  }, [fetchAuthenticatedUser])

  if (loading) return null

  return (
    <Router>
      <PrivateRoute path="app" component={DashboardPage} />
      <NotFoundPage default />
    </Router>
  )
}

export default App
