import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit"

const PREFIX = "notifications/"
const NOTIFY = `${PREFIX}NOTIFY`

export interface NotifyPayload {
  message: string
  variant: "success" | "warning" | "error" | "info"
}

export const notify: ActionCreatorWithPayload<NotifyPayload> = createAction(
  NOTIFY,
)
