import * as React from "react"

import { SEO } from "../../components"

const AboutPage: React.FC<{}> = () => (
  <React.Fragment>
    <SEO title="About us" description="" />
    <h1>About us</h1>
    <p>...</p>
  </React.Fragment>
)

export default AboutPage
