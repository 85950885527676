import { createReducer } from "@reduxjs/toolkit"

import { setCurrentUser } from "./actions"

interface AuthState {
  currentUser: any
}

const reducer = createReducer(
  {
    currentUser: null,
  } as AuthState,
  {
    [setCurrentUser.type]: (state, action: any) => {
      state.currentUser = action.payload
    },
  },
)

export default reducer
