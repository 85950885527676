import { Auth, Logger } from "aws-amplify"
import { Field, Formik, FormikHelpers, FormikProps } from "formik"
import { PasswordField } from "formik-material-ui-elements"
import { DialogActions, DialogTitle, Form } from "formik-material-ui-elements"
import React, { ReactNode, useMemo } from "react"
import { useDispatch } from "react-redux"
import * as yup from "yup"

import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"

import { notify } from "../notifications"

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required("Required"),
  newPassword: yup.string().required("Required")
  .matches(
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  ),
  passwordConfirmation: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("newPassword")], "Passwords don't match"),
})

interface FormValues {
  oldPassword: string
  newPassword: string
  passwordConfirmation: string
}

interface Props {
  open: boolean
  onClose: () => void
}

const PasswordChangeDialog: React.FC<Props> = ({ open, onClose }: Props) => {
  const logger = useMemo(() => new Logger(PasswordChangeDialog.name), [])
  const dispatch = useDispatch()

  const initialValues: FormValues = {
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  }

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    const { oldPassword, newPassword } = values
    let close = false
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      dispatch(
        notify({
          message: "Password changed successfully!",
          variant: "success",
        }),
      )
      close = Boolean(onClose)
    } catch (err) {
      logger.error(err)

      if (err.code === "InvalidParameterException") {
        dispatch(
          notify({
            message: err.message,
            variant: "error",
          }),
        )
      } else {
        dispatch(
          notify({
            message: "There was a problem changing your password!",
            variant: "error",
          }),
        )
      }
    } finally {
      actions.setSubmitting(false)
      actions.resetForm({})
    }

    if (close) {
      onClose()
    }
  }

  const renderFields = (): ReactNode => (
    <>
      <Field
        component={PasswordField}
        id="oldPassword"
        name="oldPassword"
        label="Old Password"
        autoFocus
        color="secondary"
        size="small"
        required
      />
      <Field
        component={PasswordField}
        id="newPassword"
        name="newPassword"
        label="New Password"
        color="secondary"
        size="small"
        required
      />
      <Field
        component={PasswordField}
        id="passwordConfirmation"
        name="passwordConfirmation"
        label="Confirm Password"
        color="secondary"
        size="small"
        required
      />
    </>
  )

  const renderForm = ({
    dirty,
    submitForm,
    isSubmitting,
  }: FormikProps<FormValues>): ReactNode => (
    <Form noValidate>
      <DialogTitle id="form-dialog-title" onClose={onClose}>
        Change Password
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>Enter your new password below.</DialogContentText>
        {renderFields()}
      </DialogContent>
      <DialogActions>
        {isSubmitting ? <CircularProgress /> : null}
        <Button
          color="primary"
          onClick={submitForm}
          disabled={!dirty || isSubmitting}
        >
          Change Password
        </Button>
        {onClose ? (
          <Button color="primary" onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
        ) : null}
      </DialogActions>
    </Form>
  )

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {renderForm}
      </Formik>
    </Dialog>
  )
}

export default PasswordChangeDialog
