// import "./src/styles/theme.css"
// using the material-ui theme, just import the fonts...
import "./src/fonts/stylesheet.css"

import configureAmplify from "./src/configureAmplify"
import wrapPageElement from "./src/wrapPageElement"
import wrapRootElement from "./src/wrapRootElement"

configureAmplify()

export { wrapPageElement, wrapRootElement }
