/* eslint-disable @typescript-eslint/camelcase */
import { Auth, Logger } from "aws-amplify"
import { FormikHelpers } from "formik"
import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useGlobalStyles } from "../../styles/globalStyles"
import { useSelector } from "react-redux"
import { RootState } from "../../state/rootReducer"
import { isMobile } from "react-device-detect"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

import { useNotify } from "../../notifications"
import ForgotPasswordForm, { FormValues } from "./ForgotPasswordForm"
import FormContainer from "./FormContainer"
import { Wrapper, PageContainer, LogoContainer } from "./SignUpPage"
import styled from "styled-components"
import CompactFooter from "../CompactFooter"
import { Logo, SEO } from ".."
import { formatPhoneNumber } from "../../utils/format"

const ForgotPasswordContainer = styled.div`
  padding: 1rem 0;
  width: 700px;
  @media (max-width: 1024px) {
    padding-right: 0px;
  }

  @media (max-width: 960px) {
    padding: 120px 50px 0px 50px;
  }

  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
`

const ForgotPasswordPage: React.FC<{}> = () => {
  const logger = useMemo(() => new Logger(ForgotPasswordPage.name), [])
  const notify = useNotify()
  const globalStyles = useGlobalStyles()

  const notifications: any = useSelector<RootState>(
    (state) => state.notifications,
  )

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    const { phone_number } = values
    try {
      const phoneNumber = formatPhoneNumber(phone_number)
      await Auth.forgotPassword(phoneNumber)
      navigate("/reset-password", {
        state: { phone_number },
      })
    } catch (err) {
      logger.error(err)
      notify.error("User does not exist.")
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <PageContainer>
      <LogoContainer>
        <Logo width={380} height={100} />
      </LogoContainer>
      <Wrapper>
        <ForgotPasswordContainer>
          <Container component="main">
            <SEO title="Forgot password" />
            <FormContainer>
              <Typography variant="h4" className={globalStyles.fontSemiBold}>
                Forgot password
              </Typography>
              <Typography
                variant="subtitle1"
                className={globalStyles.textLightGrey}
              >
                Send password reset instructions
              </Typography>
              {notifications?.notification?.variant === "error" && (
                <div className="erroText">
                  {notifications?.notification?.message}
                </div>
              )}

              {notifications?.notification?.variant === "success" && (
                <div className="successText">
                  {notifications?.notification?.message}
                </div>
              )}
              <Box
                component="div"
                mt={5}
                mx={"auto"}
                style={{ width: isMobile ? "100%" : "60%" }}
              >
                <ForgotPasswordForm onSubmit={handleSubmit} />
              </Box>
              <Grid container justify="center">
                <Grid item>
                  <Link
                    href="/sign-in"
                    color="primary"
                    className={globalStyles.fontSemiBold}
                  >
                    Remember password? Sign in
                  </Link>
                </Grid>
              </Grid>
            </FormContainer>
          </Container>
        </ForgotPasswordContainer>
      </Wrapper>
      <CompactFooter />
    </PageContainer>
  )
}

export default ForgotPasswordPage
