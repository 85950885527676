import defaultTheme from "../defaultTheme";
import paywellTheme from "../paywellTheme";
import eshandiTheme from "../eshandiTheme";
import payAnyDayTheme from "../payAnyDayTheme";

const themeSelector = (companyName: string) => {
  switch (companyName) {
    case "paywell":
      return paywellTheme;
    case "eshandi":
      return eshandiTheme;
    case "payanyday":
        return payAnyDayTheme;
    default:
      return defaultTheme;
  }
};

export default themeSelector;