import React from "react"

import { ScrollProvider } from "../context/Scroll"
import Footer from "./Footer"
import Header from "./Header"

interface Props {
  children?: any
}

const BrochureContainer: React.FC<Props> = ({ children }: Props) => {
  return (
    <ScrollProvider>
      <Header />
      {children}
      <Footer />
    </ScrollProvider>
  )
}

export default BrochureContainer
