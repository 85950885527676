import { Field, Formik, FormikHelpers, FormikProps } from "formik"
import { Form, SubmitButton, TextField } from "formik-material-ui-elements"
import React, { ReactNode } from "react"
import * as yup from "yup"
import styled from "styled-components"

const validationSchema = yup.object().shape({
  code: yup.string().required("Required"),
})

const ConfirmButton = styled(SubmitButton)`
  margin:auto;
  border-radius: 8px;
`

export interface FormValues {
  code: string
}

interface Props {
  onSubmit: (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => void | Promise<any>
  initialValues?: FormValues
}

const defaultValues = {
  code: "",
}

const ConfirmSignUpForm: React.FC<Props> = ({
  onSubmit,
  initialValues = defaultValues,
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }: FormikProps<FormValues>): ReactNode => (
      <Form noValidate>
        <Field
          autoFocus
          component={TextField}
          id="code"
          label="Verification Code"
          name="code"
          size="small"
          color="secondary"
          required
        />
        <ConfirmButton
          color="secondary"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Confirm
        </ConfirmButton>
      </Form>
    )}
  </Formik>
)

export default ConfirmSignUpForm
