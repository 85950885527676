/* eslint-disable @typescript-eslint/camelcase */
import { Field, Formik, FormikHelpers, FormikProps } from "formik"
import {
  Form,
  PasswordField,
  SubmitButton,
  TextField,
} from "formik-material-ui-elements"
import React, { ReactNode } from "react"
import * as yup from "yup"
import styled from "styled-components"

const LoginButton = styled(SubmitButton)`
  margin: auto;
  border-radius: 8px;
`
const validationSchema = yup.object().shape({
  phone_number: yup.string().required("Required"),
  password: yup.string().required("Required"),
})

export interface FormValues {
  phone_number: string
  password: string
}

interface Props {
  onSubmit: (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => void | Promise<any>
  initialValues?: FormValues
}

const defaultValues = {
  phone_number: "",
  password: "",
}

const SignInForm: React.FC<Props> = ({
  onSubmit,
  initialValues = defaultValues,
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }: FormikProps<FormValues>): ReactNode => (
      <Form noValidate>
        <Field
          autoComplete="tel"
          component={TextField}
          id="phone_number"
          label="Mobile Number"
          name="phone_number"
          size="small"
          color="secondary"
          required
        />
        <Field
          component={PasswordField}
          id="password"
          label="Password"
          name="password"
          size="small"
          color="secondary"
          required
        />
        <LoginButton
          color="primary"
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Login
        </LoginButton>
      </Form>
    )}
  </Formik>
)

export default SignInForm
