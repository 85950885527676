import React, { PureComponent, createContext, RefObject } from "react"

interface Section {
  elementRef: RefObject<any>
  name: string
}

interface Context {
  sectionInView: string | null
  scrolling: boolean
  sections: Section[]
  registerSection?: (section: Section) => void
  setActiveSection?: (sectionName: string) => void
  setScrolling?: (scrolling: boolean) => void
  scrollToSection?: (sectionName: string) => void
}

export const defaultContext: Context = {
  sectionInView: null,
  scrolling: false,
  sections: [],
}

export const ScrollContext = createContext(defaultContext)

interface Props {
  children: any
}

export class ScrollProvider extends PureComponent<Props> {
  sections: Section[] = []
  frameId = 0

  state = {
    ...defaultContext,
    registerSection: (section: Section): void => {
      this.sections.push(section)
      this.setState({
        sections: this.sections,
      })
    },
    setActiveSection: (sectionName: string): void => {
      this.setState({
        sectionInView: sectionName,
      })
    },
    setScrolling: (scrolling: boolean): void => {
      this.setState({ scrolling })
    },
    scrollToSection: (sectionName: string): void => {
      for (const section of this.state.sections) {
        if (section.name === sectionName) {
          window.scrollTo({
            top: section.elementRef.current.offsetTop,
            behavior: "smooth",
          })
          return
        }
      }
    },
  }

  setSectionInView = (): void => {
    let sectionInView = null
    for (const section of this.state.sections) {
      const topOffset = (window.innerHeight * 50) / 100
      const { y } = section.elementRef.current.getBoundingClientRect()
      if (y - topOffset < 0) {
        sectionInView = section.name
      }
    }

    this.setState({ sectionInView })
  }

  onScroll = (): void => {
    this.frameId = window.requestAnimationFrame(this.setSectionInView)
  }

  componentDidMount(): void {
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount(): void {
    window.removeEventListener("scroll", this.onScroll)
    if (this.frameId) {
      window.cancelAnimationFrame(this.frameId)
    }
  }

  render() {
    return (
      <ScrollContext.Provider value={this.state}>
        {this.props.children}
      </ScrollContext.Provider>
    )
  }
}
