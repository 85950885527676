import { combineReducers } from "redux"

import { reducer as authReducer } from "../components/auth"
import { reducer as notificationsReducer } from "../notifications"

const rootReducer = combineReducers({
  auth: authReducer,
  notifications: notificationsReducer,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
