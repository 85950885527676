import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { notify } from "./actions"

interface NotifyResult {
  success: (message: string | undefined) => void
  warning: (message: string) => void
  error: (message: string) => void
  info: (message: string) => void
}

export const useNotify = (): NotifyResult => {
  const dispatch = useDispatch()

  const success = useCallback(
    (message) => {
      dispatch(
        notify({
          variant: "success",
          message: message || "Successful",
        }),
      )
    },
    [dispatch],
  )

  const warning = useCallback(
    (message) => {
      dispatch(
        notify({
          variant: "warning",
          message,
        }),
      )
    },
    [dispatch],
  )

  const error = useCallback(
    (message) => {
      dispatch(
        notify({
          variant: "error",
          message,
        }),
      )
    },
    [dispatch],
  )

  const info = useCallback(
    (message) => {
      dispatch(
        notify({
          variant: "info",
          message,
        }),
      )
    },
    [dispatch],
  )

  return { success, warning, error, info }
}
