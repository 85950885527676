import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client"
import { Auth } from "aws-amplify"
import fetch from "isomorphic-unfetch"

// import introspectionQueryResultData from "./fragmentTypes.json"

let apolloClient: ApolloClient<NormalizedCacheObject>

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch
}

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// })

const link = new HttpLink({
  uri: `${process.env.GRAPHQL_URI}`,
  fetch: async (input, init): Promise<Response> => {
    const session = await Auth.currentSession()
    if (init && init.headers) {
      const jwtToken = session.getIdToken().getJwtToken()
      // @ts-ignore
      init.headers["Authorization"] = jwtToken // TODO: Figure out why TypeScript complains about setting "Authorization" directly on `init.headers`
    }
    return fetch(input, init)
  },
  fetchOptions: {
    "Access-Control-Allow-Origin": "*",
  },
})

const createApolloClient = (
  initialState = {},
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // disable forceFetch on the server (so queries are only run once)
    link,
    cache: new InMemoryCache({
      /*fragmentMatcher*/
    }).restore(initialState),
  })
}

const getApolloClient = (
  initialState = {},
): ApolloClient<NormalizedCacheObject> => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return createApolloClient(initialState)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState)
  }

  return apolloClient
}

export default getApolloClient
